<!--
 * @Description: 批量添加
 * @Author: xiawenlong
 * @Date: 2021-04-22 15:03:59
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-03 16:18:24
-->
<template>
  <el-dialog
    class="student-batch-import"
    title="批量导入员工"
    width="750px"
    :visible.sync="dialogVisible"
  >
    <el-upload
      class="upload-batch"
      drag
      action=""
      :auto-upload="false"
      :file-list="fileList"
      :on-change="handleChange"
    >
      <i class="el-icon-plus"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div slot="tip" class="el-upload__tip">
        <div class="btns">
          <el-button @click="downloadTemplate">下载导入模板</el-button>
        </div>
        <p class="title">模版说明：</p>
        <p>员工姓名：员工的真实姓名。</p>
        <p>身份证/护照：员工对应的18位身份证号、外籍员工填写护照编号。</p>
        <p>员工手机号：员工的手机号。</p>
        <p>部门（选填）：员工在书院对应的部门。</p>
        <!-- <p>加入班级（选填）：填写后导入学员自动添加进入班级。</p> -->
      </div>
    </el-upload>
    <div class="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="importSubmit">确定导入</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { studentTemplate, studentImport } from '@/api/student'
import to from 'await-to'
export default {
  name: 'StudentBatchAdd',
  data() {
    return {
      dialogVisible: false,
      loading: false,
      fileList: [],
      deptId: '',
    }
  },
  methods: {
    open(deptId) {
      this.deptId = deptId
      this.dialogVisible = true
    },
    async downloadTemplate() {
      const [res, err] = await to(studentTemplate())
      if (err) return this.$message.warning(err.msg)
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      if (window.navigator.msSaveOrOpenBlob) {
        //兼容IE10
        navigator.msSaveBlob(blob, this.filename)
      } else {
        const href = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href
        a.download = '导入模板'
        a.click()
        URL.revokeObjectURL(a.href)
      }
    },
    handleChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.fileList = []
        return this.$message.error('上传文件大小不能超过 5MB!')
      }
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
    },
    async importSubmit() {
      if (this.loading) return
      if (!this.fileList.length) return this.$message.warning('请先选择文件')
      let formData = new FormData()
      if (this.deptId == undefined) {
        this.deptId = ''
      }
      formData.append('file', this.fileList[0].raw)
      formData.append('deptId', this.deptId)
      this.loading = true
      const [, err] = await to(studentImport(formData))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('导入成功')
      this.$router.push('/student/department')
    },
  },
}
</script>
<style lang="scss" scoped>
.student-batch-import {
  ::v-deep.upload-batch {
    position: relative;
    .el-upload-list {
      width: 420px;
      position: absolute;
      left: 165px;
      top: 96px;
    }
    .el-upload {
      display: block;
      &__tip {
        width: 420px;
        margin: 0 auto;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        margin-bottom: 52px;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        p {
          &.title {
            margin-bottom: 22px;
          }
        }
        .btns {
          padding: 0 60px;
          margin-bottom: 40px;
          text-align: center;
        }
      }
      .el-upload__text {
        font-size: 12px;
        color: #979797;
      }
      .el-upload-dragger {
        margin: 0 auto;
        width: 420px;
        height: 92px;
        background: #f5f5f5;
        border: 1px solid #d5d5d5;
        border-radius: 4px;
        margin-bottom: 77px;
        padding-top: 23px;
        .el-icon-plus {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }
  ::v-deep.el-dialog__header {
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    font-weight: 500;
    color: #4d4e5d;
    padding: 0 30px;
    border-bottom: 1px solid #c7c7c7;
    .el-dialog__headerbtn {
      right: 30px;
    }
  }
  ::v-deep.el-dialog__body {
    padding: 40px 0 0 0;
  }
  .footer {
    height: 80px;
    line-height: 80px;
    text-align: right;
    border-top: 1px solid #c7c7c7;
    padding: 0 38px;
    .el-button {
      width: 98px;
      height: 40px;
      text-align: center;
      &:last-child {
        width: 110px;
        margin-left: 28px;
      }
    }
  }
}
</style>
