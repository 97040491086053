<!--
 * @Description: 上传图片组件
 * @Author: xiawenlong
 * @Date: 2020-12-19 14:23:46
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-03 17:34:59
-->
<template>
  <div class="upload-image">
    <!-- <el-upload
      class="uploader"
      action=""
      :limit="1"
      :file-list="fileList"
      list-type="picture-card"
      :http-request="uploadRequest"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      accept=".jpg,.jpeg,.png,.gif,.svg,.JPG,.JPEG"
    >
      <i class="el-icon-upload"></i>
    </el-upload> -->
    <el-upload
      class="uploader"
      action=""
      :show-file-list="false"
      :http-request="uploadRequest"
      :before-upload="beforeAvatarUpload"
      accept=".jpg,.jpeg,.png,.gif,.svg,.JPG,.JPEG"
    >
      <img v-if="image" :src="image" class="avatar" />
      <i v-else class="el-icon-upload"></i>
    </el-upload>
  </div>
</template>
<script>
import { getFileUpload, getWatermark } from '@/api/college'
import to from 'await-to'
export default {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'setModelVal',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    uploadImg: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: '',
    },
    widthSize: {
      type: Number,
      default: 0,
    },
    heightSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      image: '',
      fileList: [],
      obj: {},
    }
  },
  watch: {
    value(val) {
      this.image = val
    },
  },
  methods: {
    async uploadRequest(file) {
      const params = new FormData()
      params.append('fileTypeCode', 'F001')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(
        this.status && this.status == 'watermark' ? getWatermark(params) : getFileUpload(params),
      )
      if (err) {
        this.image = ''
        return this.$message.error(err.msg)
      }
      this.$emit('setModelVal', res.data)
      this.$emit('successWH', this.obj)
      this.$emit('successUpload', 1)
      this.uploadImg.clearValidate()
      // this.image = res.data.fileUrl
    },
    beforeAvatarUpload(file) {
      let that = this
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.error('上传文件类型必须是图片!')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }

      if (this.widthSize != 0) {
        const isSize = new Promise((resolve, reject) => {
          let width = this.widthSize
          let height = this.heightSize
          let _URL = window.URL || window.webkitURL
          let img = new Image()
          img.onload = function() {
            let valid = img.width == width && img.height == height
            that.obj = {
              width: img.width,
              height: img.height,
            }
            valid ? resolve() : reject()
          }
          img.src = _URL.createObjectURL(file)
        }).then(
          () => {
            return file
          },
          err => {
            console.log(err)
            this.$message.error(
              '图片大小在' +
                this.widthSize +
                '*' +
                this.heightSize +
                '，支持扩展名：.png .jpg .jpeg',
            )
            return Promise.reject()
          },
        )
        return isImage && isLt5M && isSize
      } else {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
          //让页面中的img标签的src指向读取的路径
          var img = new Image()
          img.src = reader.result
          img.onload = function() {
            that.obj = {
              width: img.width,
              height: img.height,
            }
          }
        }
        return isImage && isLt5M
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep.uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    position: relative;
    width: 178px;
    height: 178px;
    img {
      @extend %width-height;
    }
    .el-icon-upload {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -29px;
      margin-left: -29px;
      font-size: 58px;
    }
    &:hover {
      border-color: #ff7b33;
    }
  }
}
</style>
