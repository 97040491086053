<template>
  <div class="index">
    <el-dialog
      title="添加部门"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-input v-model="deptName" placeholder="请输入部门名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addbtnMsg">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑部门"
      :visible.sync="dialogVisible1"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-input v-model="deptName" placeholder="请输入部门名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="editbtnMsg">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加员工"
      :visible.sync="dialogVisible2"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="员工姓名：" prop="studentName">
          <el-input v-model="form.studentName"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="mobile">
          <el-input v-model="form.mobile" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input v-model="form.cardNo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="handleAddbtn('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑员工"
      :visible.sync="dialogVisible3"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="员工姓名：" prop="studentName">
          <el-input v-model="form.studentName"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="mobile">
          <el-input v-model="form.mobile" disabled></el-input>
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input v-model="form.cardNo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="handleEditbtn('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="调整部门"
      :visible.sync="dialogVisible4"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-tree
        ref="tree"
        :data="tree"
        node-key="id"
        check-strictly
        highlight-current
        default-expand-all
        :props="defaultProps"
        @node-click="handleNodeClickBtn"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="handleMovebtn">确 定</el-button>
      </span>
    </el-dialog>
    <div class="tree_box">
      <div class="title">
        <span class="text">组织架构</span>
        <span class="icon_img" @click="addbtn()"><i class="el-icon-plus"></i></span>
        <span class="icon_img" @click="editbtn()"> <i class="el-icon-edit-outline"></i></span>
        <span class="icon_img" @click="removebtn()"> <i class="el-icon-delete"></i></span>
      </div>
      <el-tree
        ref="tree"
        class="tree"
        :data="tree"
        node-key="id"
        check-strictly
        highlight-current
        default-expand-all
        :props="defaultProps"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
    <div class="table_box">
      <table-list
        title="部门员工"
        :loading="loading"
        :search-form="searchForm"
        :data="studentList"
        :columns="columns(this)"
        :operates="operates(this)"
        :btns="btns(this)"
        :pager="pager"
        :options="{ selection: true, fixScrollTable: true }"
        @search="onSearch"
        @selectionChange="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
      <StudentInvite ref="StudentInvite" />
    </div>
    <BatchImport ref="batchImport" />
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加员工',
    method: _this.handleAdd,
  },
  {
    label: '批量添加员工',
    method: _this.handleBatchAdd,
  },
  {
    label: '调整部门',
    method: _this.handleMove,
  },
  {
    label: '邀请员工',
    method: _this.handleInvite,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
  {
    label: '导出所有人员',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = [
  {
    label: '员工姓名',
    prop: 'studentName',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '员工名称',
    minWidth: 120,
  },
  {
    prop: 'mobile',
    label: '手机号码',
    minWidth: 150,
  },
  {
    prop: 'cardNo',
    label: '身份证/护照',
    minWidth: 200,
  },
  {
    prop: 'deptName',
    label: '所在部门',
    minWidth: 150,
  },
  {
    prop: 'loginTime',
    label: '最后一次登录时间',
    sortable: 'sortable',
    minWidth: 200,
  },
  {
    prop: 'createTime',
    label: '注册时间',
    sortable: 'sortable',
    minWidth: 150,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
]
import TableList from '@/components/TableList'
import StudentInvite from '../components/StudentInvite'
import BatchImport from './batchImport.vue'
import {
  treeList,
  addDept,
  editDept,
  removeDept,
  students,
  addStudents,
  updateStudents,
  removeStudents,
  changeDept,
  exportList,
} from '@/api/department'
import to from 'await-to'
export default {
  components: {
    TableList,
    StudentInvite,
    BatchImport,
  },
  data() {
    return {
      searchForm,
      columns,
      operates,
      btns,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      tree: [],
      deptId: null,
      deptIdBtn: null,
      deptName: '',
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      studentList: [],
      defaultProps: {
        id: 'deptId',
        children: 'depList',
        label: function(data) {
          return data.deptName + '(' + data.studentNum + ')' + '人'
        },
      },
      form: {
        studentName: '',
        mobile: '',
        cardNo: '',
        studentId: '',
      },
      rules: {
        studentName: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' },
          { min: 1, max: 12, message: '请输入1 -12 员工姓名', trigger: 'blur' },
        ],
        mobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
      },
      deptInfo: {},
    }
  },
  mounted() {
    this.getTreeList()
    this.getClassListData()
  },
  methods: {
    //树形图
    async getTreeList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(treeList({}))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.tree = Array(res.data)
    },
    async getClassListData() {
      const { current, size } = this.pager
      const deptIds = new Array()
      if (this.deptId != null) deptIds.push(this.deptId)
      const [res, err] = await to(students({ current, size, deptIds, ...this.queryParams }))
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.records
      this.pager.total = res.data.total
    },
    //树形选择节点
    handleNodeClick(data) {
      console.log('11111', data)
      this.deptInfo = data
      this.deptId = data.deptId
      this.pager.current = 1
      this.getClassListData()
    },
    handleNodeClickBtn(data) {
      this.deptIdBtn = data.deptId
    },
    //添加按钮
    async addbtn() {
      if (this.deptId == null) return this.$message.warning('请选择部门！')
      this.dialogVisible = true
    },
    //添加部门
    async addbtnMsg() {
      if (this.deptName == '') return this.$message.warning('请输入部门名称！')
      if (this.deptName.length < 2) return this.$message.warning('请输入2-12字部门名称！')
      if (this.deptName.length > 12) return this.$message.warning('请输入2-12字部门名称！')
      const deptName = this.deptName
      const parentId = this.deptId
      const [, err] = await to(addDept({ parentId, deptName, ...this.queryParams }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功！')
      this.dialogVisible = false
      this.deptName = ''
      this.deptId = null
      this.getTreeList()
    },
    //编辑按钮
    async editbtn() {
      if (this.deptId == null) return this.$message.warning('请选择部门！')
      this.dialogVisible1 = true
    },
    //编辑部门
    async editbtnMsg() {
      if (this.deptName == '') return this.$message.warning('请输入部门名称！')
      if (this.deptName.length < 2) return this.$message.warning('请输入2-12字部门名称！')
      if (this.deptName.length > 12) return this.$message.warning('请输入2-12字部门名称！')
      const deptName = this.deptName
      const deptId = this.deptId
      const [, err] = await to(editDept({ deptId, deptName, ...this.queryParams }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('编辑成功！')
      this.dialogVisible1 = false
      this.deptName = ''
      this.deptId = null
      this.getTreeList()
    },
    //删除部门
    async removebtn() {
      if (this.deptId == null) return this.$message.warning('请选择部门！')
      console.log(this.deptInfo)
      let text = ''
      if (this.deptInfo.studentNum == 0 && this.deptInfo.depList.length == 0) {
        text = '确认删除当前部门吗'
      } else if (this.deptInfo.studentNum != 0 && this.deptInfo.depList.length != 0) {
        text = '当前部门下存在员工，删除后则需要重新分配，是否继续?'
      } else if (this.deptInfo.studentNum != 0 && this.deptInfo.depList.length == 0) {
        text = '当前部门下存在员工，删除后则需要重新分配，是否继续?'
      } else if (this.deptInfo.studentNum == 0 && this.deptInfo.depList.length != 0) {
        text = '当前部门下存在多个子部门，是否确认删除?'
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const deptId = this.deptId
        const [, err] = await to(removeDept({ deptId, ...this.queryParams }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功！')
        this.deptName = ''
        this.deptId = null
        this.getTreeList()
      })
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getClassListData()
    },
    // 添加学员按钮
    async handleAdd() {
      if (this.deptId == null) return this.$message.warning('请选择部门！')
      this.dialogVisible2 = true
    },
    //添加学员
    handleAddbtn(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const studentName = this.form.studentName
          const mobile = this.form.mobile
          const cardNo = this.form.cardNo
          const deptId = this.deptId
          const [, err] = await to(
            addStudents({ deptId, studentName, mobile, cardNo, ...this.queryParams }),
          )
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          this.dialogVisible2 = false
          this.form.studentName = ''
          this.form.mobile = ''
          this.form.cardNo = ''
          this.getClassListData()
          setTimeout(() => {
            window.location.reload()
          }, 500)
        } else {
          return false
        }
      })
    },
    // 批量
    async handleBatchAdd() {
      if (this.deptId == null) return this.$message.warning('请选择部门！')
      // this.$router.push({
      //   path: `/student/batchAdd`,
      //   query: {
      //     deptId: this.deptId,
      //   },
      // })
      this.$refs.batchImport.open(this.deptId)
    },
    //调整
    handleMove() {
      if (!this.selectStudents) return this.$message.warning('请先选择员工')
      this.dialogVisible4 = true
    },
    async handleMovebtn() {
      const deptId = this.deptIdBtn
      let studentIds = []
      this.selectStudents.map(item => studentIds.push(item.studentId))
      const [, err] = await to(changeDept({ studentIds, deptId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('调整部门成功')
      this.deptIdBtn = null
      this.dialogVisible4 = false
      this.getClassListData()
      this.getTreeList()
    },
    // 邀请
    handleInvite() {
      this.$refs['StudentInvite'].show()
    },
    // 编辑
    async handleEdit(row) {
      this.form.studentName = row.studentName
      this.form.mobile = row.mobile
      this.form.cardNo = row.cardNo
      this.form.studentId = row.studentId
      this.dialogVisible3 = true
    },
    handleEditbtn(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.isIdentityId(this.form.cardNo) == false) {
            this.$message.warning('身份证格式有误，请重新输入')
            return
          }
          const studentName = this.form.studentName
          const studentId = this.form.studentId
          const cardNo = this.form.cardNo
          const [, err] = await to(
            updateStudents({ studentName, studentId, cardNo, ...this.queryParams }),
          )
          if (err) return this.$message.warning(err.msg)
          this.$message.success('编辑成功')
          this.dialogVisible3 = false
          this.form.studentName = ''
          this.form.mobile = ''
          this.form.cardNo = ''
          this.getClassListData()
        } else {
          return false
        }
      })
    },
    // 删除
    async handleDelete() {
      if (!this.selectStudents) return this.$message.warning('请先选择要删除的员工')
      let studentIds = []
      this.selectStudents.map(item => studentIds.push(item.studentId))
      this.$confirm('确认删除员工吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(removeStudents({ studentIds }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getClassListData()
      })
    },
    async handleExport() {
      const [res, err] = await to(exportList({}))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('导出成功')
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([res]))
      link.target = '_blank'
      //文件名和格式
      link.download = '人员名单.xlsx'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 多选
    handleSelectionChange(val) {
      this.selectStudents = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getClassListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getClassListData()
    },
    //校验身份证号
    isIdentityId(identityId) {
      var patrn = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/ //长度或格式校验
      //地区校验
      var aCity = {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆',
        71: '台湾',
        81: '香港',
        82: '澳门',
        91: '国外',
      }
      // 出生日期验证
      var sBirthday = (
          identityId.substr(6, 4) +
          '-' +
          Number(identityId.substr(10, 2)) +
          '-' +
          Number(identityId.substr(12, 2))
        ).replace(/-/g, '/'),
        d = new Date(sBirthday)
      // 身份证号码校验 最后4位  包括最后一位的数字/字母X
      var sum = 0,
        weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
        codes = '10X98765432'
      for (var i = 0; i < identityId.length - 1; i++) {
        sum += identityId[i] * weights[i]
      }
      var last = codes[sum % 11] //计算出来的最后一位身份证号码

      var errorMsg = true
      if (identityId === '') {
        errorMsg = true
      } else if (!patrn.exec(identityId)) {
        errorMsg = false
      } else if (!aCity[parseInt(identityId.substr(0, 2))]) {
        errorMsg = false
      } else if (sBirthday != d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()) {
        errorMsg = false
      } else if (identityId[identityId.length - 1] != last) {
        errorMsg = false
      }
      return errorMsg
    },
  },
}
</script>
<style scoped lang="scss">
.tree {
  ::v-deep .el-tree-node__label {
    width: 228px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.index {
  display: flex;
  justify-content: space-between;
}
.title {
  height: 50px;
  padding: 0 10px;
  font-size: 18px;
  line-height: 50px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.tree_box {
  background-color: #fff;
  width: 20%;
}
.table_box {
  width: 78%;
}
.text {
  margin-right: 150px;
}
.icon_img {
  margin-left: 10px;
  cursor: pointer;
}
.tree_box >>> .el-tree-node__label {
  margin: 5px 0;
  font-size: 16px;
}
.tree_box >>> .el-tree-node__content {
  height: 30px;
}
</style>
