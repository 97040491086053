import config from './config/department'
import axios from '@/utils/request'
export const treeList = params => axios.post(config.treeList, params)
export const addDept = params => axios.post(config.addDept, params)
export const editDept = params => axios.post(config.editDept, params)
export const removeDept = params => axios.post(config.removeDept, params)
export const students = params => axios.post(config.students, params)
export const addStudents = params => axios.post(config.addStudents, params)
export const updateStudents = params => axios.post(config.updateStudents, params)
export const removeStudents = params => axios.post(config.removeStudents, params)
export const changeDept = params => axios.post(config.changeDept, params)
export const exportList = params => axios.post(config.exportList, params, { responseType: 'blob' })
