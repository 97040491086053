<!--
 * @Description: 学员编辑
 * @Author: xiawenlong
 * @Date: 2021-04-23 16:23:28
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-07-01 14:23:45
-->
<template>
  <div class="student-list-edit">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">编辑员工</span>
      </div>
      <el-form ref="studentForm" :model="studentForm" label-width="100px" :rules="rules">
        <el-form-item label="员工姓名" prop="studentName">
          <el-input v-model="studentForm.studentName" placeholder="请输入姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="员工手机号">
          <el-input
            v-model="studentForm.mobile"
            disabled
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证">
          <el-input v-model="studentForm.cardNo" placeholder="请输入身份证" clearable></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-cascader
            v-model="studentForm.deptPath"
            :options="deptList"
            :props="deptProps"
            clearable
            @change="handleDeptChange"
          >
          </el-cascader>
        </el-form-item>
        <!-- <el-form-item label="部门">
          <el-select v-model="studentForm.depts" multiple placeholder="请选择部门" clearable>
            <el-option
              v-for="item in deptList"
              :key="item.deptId"
              :label="item.deptName"
              :value="item.deptId"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="班级">
          <el-select v-model="studentForm.classes" multiple placeholder="请选择班级" clearable>
            <el-option
              v-for="item in classList"
              :key="item.classId"
              :label="item.className"
              :value="item.classId"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item ref="headPic" label="员工头像" prop="headPic">
          <upload-image v-model="studentForm.headPic" :upload-img="$refs.headPic"></upload-image>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="update">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { studentInfo, studentUpdate } from '@/api/student'
import { deptTree } from '@/api/dept'
import { classSelect } from '@/api/class'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
export default {
  name: 'StudentListEdit',
  components: { UploadImage },
  data() {
    return {
      rules: {
        studentName: [{ required: true, message: '请输入员工姓名', trigger: 'blur' }],
      },
      studentForm: {},
      deptList: [],
      classList: [],
      loading: false,
      deptProps: {
        expandTrigger: 'hover',
        multiple: false,
        value: 'deptId',
        label: 'name',
      },
    }
  },
  mounted() {
    this.deptTree()
    this.classSelect()
    this.getStudentInfo()
  },
  methods: {
    async getStudentInfo() {
      const { studentId } = this.$route.params
      const [res, err] = await to(studentInfo({ studentId }))
      if (err) return this.$message.warning(err.msg)
      this.studentForm = res.data
      // 单选情况下，方便回显。以后支持多选，删除该行代码即可。
      this.studentForm.deptPath =
        this.studentForm.deptPath.length == 0 ? [] : this.studentForm.deptPath[0]
    },
    // 部門下拉
    async deptTree() {
      const [res, err] = await to(deptTree({}))
      if (err) return this.$message.warning(err.msg)
      this.deptList = res.data
    },
    // 班級下拉
    async classSelect() {
      const [res, err] = await to(classSelect({}))
      if (err) return this.$message.warning(err.msg)
      this.classList = res.data
    },
    // 部门
    handleDeptChange(node) {
      if (node.length == 0) {
        return (this.studentForm.depts.length = 0)
      }
      // 只取最后一级部门的id做参数
      this.studentForm.depts = [node[node.length - 1]]
    },
    update() {
      this.$refs['studentForm'].validate(async valid => {
        if (valid) {
          console.log(this.studentForm.cardNo)
          if (this.studentForm.cardNo && this.isIdentityId(this.studentForm.cardNo) == false) {
            this.$message.warning('身份证格式有误，请重新输入')
            return
          }
          this.loading = true
          const [, err] = await to(studentUpdate(this.studentForm))
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('编辑成功')
          this.$router.push('/student/list')
        }
      })
    },
    //校验身份证号
    isIdentityId(identityId) {
      var patrn = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/ //长度或格式校验
      //地区校验
      var aCity = {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆',
        71: '台湾',
        81: '香港',
        82: '澳门',
        91: '国外',
      }
      // 出生日期验证
      var sBirthday = (
          identityId.substr(6, 4) +
          '-' +
          Number(identityId.substr(10, 2)) +
          '-' +
          Number(identityId.substr(12, 2))
        ).replace(/-/g, '/'),
        d = new Date(sBirthday)
      // 身份证号码校验 最后4位  包括最后一位的数字/字母X
      var sum = 0,
        weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
        codes = '10X98765432'
      for (var i = 0; i < identityId.length - 1; i++) {
        sum += identityId[i] * weights[i]
      }
      var last = codes[sum % 11] //计算出来的最后一位身份证号码

      var errorMsg = true
      if (identityId === '') {
        errorMsg = true
      } else if (!patrn.exec(identityId)) {
        errorMsg = false
      } else if (!aCity[parseInt(identityId.substr(0, 2))]) {
        errorMsg = false
      } else if (sBirthday != d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()) {
        errorMsg = false
      } else if (identityId[identityId.length - 1] != last) {
        errorMsg = false
      }
      return errorMsg
    },
  },
}
</script>
