/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-22 15:40:27
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-25 11:49:29
 */
export default {
  treeList: '/course/manage/depts/depttree',
  addDept: '/course/manage/depts/create',
  editDept: '/course/manage/depts/update',
  removeDept: '/course/manage/depts/remove',
  students: '/course/manage/depts/finddeptstudent',
  addStudents: '/course/manage/dept/employee/add',
  updateStudents: '/course/manage/dept/employee/update',
  removeStudents: '/course/manage/dept/student/remove',
  changeDept: '/course/manage/dept/student/change',
  exportList: '/course/manage/depts/employees/export',
}
